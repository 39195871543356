import React, { useState, useContext } from 'react';
import Slider from 'rc-slider';
import { BsCaretRightFill, BsCaretLeftFill, BsVolumeUpFill, BsVolumeMuteFill } from 'react-icons/bs';
import { FaPause, FaPlay } from 'react-icons/fa';
import { RiPlayList2Fill } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import { isEqual } from 'lodash';
import Spinner from 'react-spinkit';
import {
  TYPE_TOGGLE_PLAY_PAUSE,
  TYPE_SET_MUTE,
  TYPE_SET_UNMUTE,
  TYPE_SET_VOLUME,
  STATE_BUFFERING,
  STATE_PLAYING,
  TYPE_SET_TRACK,
} from '../../context/globalContext';
import { downloadResource } from '../../utils/dataExtract';
import { convertSeconds } from '../../utils/timeUtil';
import { useDispatch } from 'react-redux';
import { STATE_PAUSED } from '../../store/reducer';

const MiniPlayer = (props) => {
  const { playState, playlist, currentTrackIndex, seekBarPosition, volume, mute } = props.audio;
  const [vol, setVol] = useState(Math.floor(volume * 100));
  const dispatch = useDispatch();
  const currentTrack = playlist[currentTrackIndex];

  return (
    <div className={`miniplayer-container ${props.show ? 'active' : 'hidden'}`}>
      <div className="miniplayer-inner-container">
        <div className="mini-player-seekbar-container">
          <div className="mini-player-seekbar">
            <Slider
              min={0}
              max={Number(parseInt(currentTrack?.length) || 100)}
              step={1}
              value={Math.floor(seekBarPosition)}
              trackStyle={{
                backgroundColor: '#FC650A',
                height: 14,
                borderRadius: 0,
              }}
              railStyle={{
                backgroundColor: '#EDEDED',
                height: 14,
                borderRadius: 0,
              }}
              handleStyle={{ display: 'none' }}
            />
            <div className="mini-seekbar-time-container">
              <p className="fnt-evolventa fnt-white fnt-r-12 mb-0">{`${convertSeconds(
                Math.floor(seekBarPosition)
              )}`}</p>
              <p className="fnt-evolventa fnt-dark-d fnt-r-12 mb-0">{`${convertSeconds(
                Math.ceil(Number(currentTrack?.length || 100)) - Math.floor(seekBarPosition)
              )}`}</p>
            </div>
          </div>
        </div>
        <div className="miniplayer-inner">
          <div className="miniplayer-left">
            {/* Image */}
            <div className="img-miniplayer-cover">
              <img src={currentTrack?.cover || ''} alt="" style={{ width: '5rem', height: 'auto' }} />
            </div>
            {/* Rest */}
            <div>
              <p className="m-2 fnt-evolventa bold fnt-r-16">{currentTrack?.title || ''}</p>
              <p className="m-2 fnt-evolventa fnt-r-14 fnt-dark-d">{props.bookTitle || ''}</p>
            </div>
          </div>
          <div className="miniplayer-middle">
            <div className="left-control">
              <RiPlayList2Fill color="#828282" className="resposive-icon-22" />
            </div>
            <div
              className="left-control"
              onClick={() => {
                const nextTrackIndex = currentTrackIndex === 0 ? playlist.length - 1 : currentTrackIndex - 1;
                const nextTrackId = playlist[nextTrackIndex].id;
                dispatch({
                  type: TYPE_SET_TRACK,
                  payload: { track: nextTrackId, trackIndex: nextTrackIndex },
                });
              }}
            >
              <div className="dsp-flx">
                <BsCaretLeftFill color="#000" className="resposive-icon-18 prev-left-mc" />
                <BsCaretLeftFill color="#000" className="resposive-icon-18 prev-right-mc" />
              </div>
            </div>
            <div className="mid-control">
              <div
                className="play-pause-btn-mini"
                role="button"
                onClick={() => {
                  if (playState === STATE_PLAYING) {
                    dispatch({
                      type: TYPE_TOGGLE_PLAY_PAUSE,
                      payload: STATE_PAUSED,
                    });
                  } else if (playState === STATE_PAUSED) {
                    dispatch({
                      type: TYPE_TOGGLE_PLAY_PAUSE,
                      payload: STATE_PLAYING,
                    });
                  }
                }}
              >
                {isEqual(playState, STATE_PLAYING) ? (
                  <FaPause className="resposive-icon-18" color="#FFF" />
                ) : isEqual(playState, STATE_BUFFERING) ? (
                  <Spinner className="resposive-icon-18" name="line-scale" color="#FFF" />
                ) : (
                  <FaPlay className="resposive-icon-18" color="#FFF" />
                )}
              </div>
            </div>
            <div
              className="right-control"
              onClick={() => {
                const nextTrackIndex = (currentTrackIndex + 1) % playlist.length;
                const nextTrackId = playlist[nextTrackIndex].id;
                dispatch({
                  type: TYPE_SET_TRACK,
                  payload: { track: nextTrackId, trackIndex: nextTrackIndex },
                });
              }}
            >
              <div className="dsp-flx">
                <BsCaretRightFill color="#000" className="resposive-icon-18 next-left-media-control  prev-left-mc" />
                <BsCaretRightFill color="#000" className="resposive-icon-18 next-right-media-control prev-right-mc" />
              </div>
            </div>
            <div
              className="right-control"
              onClick={() => {
                downloadResource(currentTrack.audio, `${currentTrack.title}.mp3`);
              }}
            >
              <HiDownload size={22} color="#828282" />
            </div>
          </div>
          <div className="miniplayer-right">
            <div
              className="mini-player-vol-btn dsp-flx flx-center ml-3"
              onClick={() => {
                if (mute) {
                  dispatch({ type: TYPE_SET_UNMUTE });
                } else {
                  dispatch({ type: TYPE_SET_MUTE });
                }
              }}
            >
              {mute ? <BsVolumeMuteFill size={24} color="#AFAFAF" /> : <BsVolumeUpFill size={24} color="#AFAFAF" />}
            </div>
            <div className="mini-slider-container">
              <Slider
                min={0}
                max={100}
                step={1}
                onChange={(val) => {
                  setVol(val);
                  dispatch({ type: TYPE_SET_VOLUME, payload: Math.floor(val) });
                }}
                value={mute ? 0 : vol}
                trackStyle={{ backgroundColor: '#FC650A', height: 2 }}
                railStyle={{ backgroundColor: '#EDEDED', height: 2 }}
                handleStyle={{
                  backgroundColor: '#FFF',
                  border: '1px solid #E7EAEB',
                  boxShadow: '0px 2px 6px rgba(69, 81, 84, 0.1)',
                  height: 24,
                  width: 24,
                  marginTop: '-11px',
                }}
              />
            </div>
            <div className="mini-player-close" onClick={props.handleCloseAudioPlayer}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniPlayer;
