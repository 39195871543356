function downloadBlob(blob, filename) {
  var a = document.createElement('a');
  a.download = filename;
  a.href = blob;
  // document.body.appendChild(a);
  a.click();
  a.remove();
}

export const downloadResource = (url, filename) => {
  if (typeof window !== 'undefined') {
    fetch(url, {
      'Content-Type': 'audio/mpeg',
      mode: 'no-cors',
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        let blobUrl = URL.createObjectURL(blob);
        downloadBlob(blobUrl, filename);
      })
      .catch((e) => console.error(e));
  }
};
