import React, { useState } from 'react';
import Slider from 'rc-slider';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import Spinner from 'react-spinkit';
import { RiPlayList2Fill } from 'react-icons/ri';
import { HiDownload } from 'react-icons/hi';
import { BsCaretRightFill, BsCaretLeftFill, BsVolumeUpFill, BsVolumeDownFill } from 'react-icons/bs';
import { FaPause, FaPlay, FaAngleUp, FaAngleDown, FaAngleLeft } from 'react-icons/fa';
import PlaylistRow from './playlistRow';
import { convertSeconds } from '../../utils/timeUtil';
import {
  TYPE_TOGGLE_PLAY_PAUSE,
  STATE_BUFFERING,
  STATE_PAUSED,
  STATE_PLAYING,
  TYPE_SET_SEEKBAR_POS_FOR_PLAYER,
  TYPE_LOCK_SEEKBAR_MUTEX,
  TYPE_SET_VOLUME,
  TYPE_SET_TRACK,
} from '../../store/reducer';
import 'rc-slider/assets/index.css';

const ExpandedContent = (props) => {
  const { title, book, chapter, playState, length, seekBar, cover, toggleExpand } = props;
  const { currentTrackId, playlist, currentTrackIndex, mute, volume } = useSelector((state) => state.audio);

  const [showPlaylist, setShowPlaylist] = useState(false);
  const [vol, setVol] = useState(Math.floor(volume * 100));
  const dispatch = useDispatch();

  const currentTrack = playlist[currentTrackIndex];
  const handleAudioSelection = (trackId, trackIndex) => {
    dispatch({ type: TYPE_SET_TRACK, payload: { track: trackId, trackIndex } });
  };

  return (
    <div>
      <div className="mplayer-title-container">
        <div
          onClick={() => {
            toggleExpand();
          }}
          onKeyDown={() => {}}
        >
          <FaAngleDown size={16} color={'#140D2E'} />
        </div>
        <div className="flx-1 dsp-flx flx-center">
          <p className="m-0 fnt-evolventa bold fnt-r-32">{book.name}</p>
        </div>
      </div>
      <div className="mplayer-expanded-img-container">
        <img className="mplayer-expanded-img" alt="" src={cover} />
      </div>
      <div className="mt-5">
        <div className="mobile-player-seekbar-expanded">
          <div className="ml-1 flx-1">
            <Slider
              displayName="Audio"
              min={0}
              max={Math.ceil(Number(parseInt(length)))}
              value={seekBar}
              step={1}
              onChange={(value) => {
                dispatch({
                  type: TYPE_SET_SEEKBAR_POS_FOR_PLAYER,
                  payload: { position: value },
                });
              }}
              onAfterChange={() => {
                dispatch({ type: TYPE_LOCK_SEEKBAR_MUTEX });
              }}
              trackStyle={{ backgroundColor: '#FC650A' }}
              railStyle={{ backgroundColor: '#EDEDED' }}
              handleStyle={{
                backgroundColor: '#FC650A',
                borderColor: '#FC650A',
              }}
            />
          </div>
          <div className="mobile-time-container-expanded dsp-flx pl-2">
            <p className="fnt-poppins fnt-r-16 fnt-dark-a m-0">{convertSeconds(Math.floor(seekBar))}</p>
            <p className="fnt-poppins fnt-r-16 fnt-dark-a m-0">{convertSeconds(Math.ceil(Number(length)))}</p>
          </div>
        </div>
      </div>

      <div className="mp-slider-container">
        <div className={`mp-slider ${showPlaylist ? 'slide' : ''}`}>
          <div className="mp-slider-left">
            <div className="playlist-container-full-screen">
              <div className="playlist-header-fs">
                <FaAngleLeft
                  color="#111"
                  size={28}
                  style={{ margin: '1rem 1rem 1rem 0' }}
                  onClick={() => {
                    setShowPlaylist(false);
                  }}
                  onKeyDown={() => {}}
                />
                <p className="fnt-poppins fnt-r-18 fnt-weight-600 m-0">{`Chapter ${currentTrackIndex + 1} of ${
                  playlist.length
                }`}</p>
              </div>
              <div className="playlist-mp">
                {playlist.map((track, index) => {
                  return (
                    <PlaylistRow
                      key={`track-0${index}`}
                      playing={isEqual(currentTrackId, track.id)}
                      title={track.title}
                      time={
                        isEqual(currentTrackId, track.id)
                          ? `${convertSeconds(Math.ceil(Number(currentTrack.length)) - Math.floor(seekBar))}`
                          : `-${convertSeconds(Math.ceil(Number(track.length)))}`
                      }
                      onSelect={() => {
                        handleAudioSelection(track.id, index);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mp-slider-right">
            <div className="mplayer-middle-container">
              <RiPlayList2Fill
                color="#000"
                size={22}
                onClick={() => {
                  setShowPlaylist(true);
                }}
              />
              <div className="mplayer-txt-container">
                <p className="fnt-r-22 bold fnt-evolventa mb-1">{title}</p>
                <p className="fnt-r-18 fnt-dark-d mb-1">{chapter}</p>
              </div>
              <HiDownload size={22} color="#000" />
            </div>
            <div>
              <div className="media-control-container">
                <div className="left-control">
                  <div
                    onClick={() => {
                      const nextTrackIndex = currentTrackIndex - 1;
                      const nextTrackId = playlist[nextTrackIndex].id;
                      dispatch({
                        type: TYPE_SET_TRACK,
                        payload: {
                          track: nextTrackId,
                          trackIndex: nextTrackIndex,
                        },
                      });
                    }}
                    onKeyDown={() => {}}
                  >
                    <BsCaretLeftFill size={26} color="#000" style={{ marginRight: -7 }} />
                    <BsCaretLeftFill size={26} color="#000" style={{ marginLeft: -7 }} />
                  </div>
                </div>
                <div className="mid-control">
                  <div
                    className="play-pause-btn"
                    role="button"
                    onClick={() => {
                      if (isEqual(playState, STATE_PLAYING)) {
                        dispatch({
                          type: TYPE_TOGGLE_PLAY_PAUSE,
                          payload: STATE_PAUSED,
                        });
                      } else if (isEqual(playState, STATE_PAUSED)) {
                        dispatch({
                          type: TYPE_TOGGLE_PLAY_PAUSE,
                          payload: STATE_PLAYING,
                        });
                      }
                    }}
                    onKeyDown={() => {}}
                  >
                    {isEqual(playState, STATE_PLAYING) ? (
                      <FaPause size={18} color="#FFF" />
                    ) : isEqual(playState, STATE_BUFFERING) ? (
                      <Spinner size={18} name="line-scale" color="#FFF" />
                    ) : (
                      <FaPlay size={18} color="#FFF" />
                    )}
                  </div>
                </div>
                <div className="right-control">
                  <div
                    onClick={() => {
                      const nextTrackIndex = currentTrackIndex + 1;
                      const nextTrackId = playlist[nextTrackIndex].id;
                      dispatch({
                        type: TYPE_SET_TRACK,
                        payload: {
                          track: nextTrackId,
                          trackIndex: nextTrackIndex,
                        },
                      });
                    }}
                    onKeyDown={() => {}}
                  >
                    <BsCaretRightFill size={26} color="#000" style={{ marginRight: -7 }} />
                    <BsCaretRightFill size={26} color="#000" style={{ marginLeft: -7 }} />
                  </div>
                </div>
              </div>
              <div className="media-colume-container">
                <div className="dsp-flx flx-center mr-3">
                  <BsVolumeDownFill size={24} color="#AFAFAF" />
                </div>
                <div className="volume-slider-container">
                  <Slider
                    min={0}
                    max={100}
                    step={1}
                    value={mute ? 0 : vol}
                    onChange={(val) => {
                      setVol(val);
                      dispatch({
                        type: TYPE_SET_VOLUME,
                        payload: Math.floor(val),
                      });
                    }}
                    trackStyle={{ backgroundColor: '#FC650A', height: 2 }}
                    railStyle={{ backgroundColor: '#EDEDED', height: 2 }}
                    // handleStyle={{ backgroundColor: "#FFF", border: "1px solid #E7EAEB", boxShadow: "0px 2px 6px rgba(69, 81, 84, 0.1)", height: 24, width: 24, transform: "translate(-50%, -25%)" }}
                    handleStyle={{
                      backgroundColor: '#FFF',
                      border: '1px solid #E7EAEB',
                      boxShadow: '0px 2px 6px rgba(69, 81, 84, 0.1)',
                      height: 24,
                      width: 24,
                      marginTop: '-4%',
                    }}
                  />
                </div>
                <div className="dsp-flx flx-center ml-3">
                  <BsVolumeUpFill size={24} color="#AFAFAF" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobilePlayer = ({
  cover = '',
  title = 'Loading..',
  subTitle = 'Loading...',
  playState = 'paused',
  length = 100,
  seekBar = 20,
  book,
  chapterIndex,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);
  const [closePlayer, setClosePlayer] = useState(false);
  const dispatch = useDispatch();
  const { show } = useSelector((state) => state.miniPlayer);

  return (
    <div className={`mobile-player-container ${!show ? 'close' : ''} ${expanded ? 'expand' : ''}`}>
      <div
        className="mobile-up-arrow"
        onClick={() => {
          setExpanded(!expanded);
        }}
        onKeyDown={() => {}}
      >
        {expanded ? null : <FaAngleUp size={16} color={'#ddd'} />}
      </div>
      {expanded ? (
        <ExpandedContent
          title={title}
          cover={cover}
          chapter={chapterIndex}
          playState={playState}
          length={length}
          seekBar={seekBar}
          book={book}
          toggleExpand={() => {
            setExpanded(!expanded);
          }}
        />
      ) : (
        <>
          <div className="mobile-player-upper">
            <Row>
              <Col xs="3">
                <div className="mobile-player-cover-container ">
                  <img src={cover} alt="" className="mobile-player-cover" />
                </div>
              </Col>
              <Col xs="7">
                <div className="mobile-player-title">
                  <p className="mb-1 fnt-evolventa bold">{title}</p>
                  <p className="mb-1 fnt-evolventa fnt-dark-d">{subTitle}</p>
                </div>
              </Col>
              <Col xs="2">
                <div
                  className="mobile-player-control"
                  onClick={() => {
                    if (isEqual(playState, STATE_PLAYING)) {
                      dispatch({
                        type: TYPE_TOGGLE_PLAY_PAUSE,
                        payload: STATE_PAUSED,
                      });
                    } else if (isEqual(playState, STATE_PAUSED)) {
                      dispatch({
                        type: TYPE_TOGGLE_PLAY_PAUSE,
                        payload: STATE_PLAYING,
                      });
                    }
                  }}
                  onKeyDown={() => {}}
                >
                  {playState === STATE_PLAYING ? <FaPause size={18} color="#000" /> : <FaPlay size={18} color="#000" />}
                </div>
              </Col>
            </Row>
          </div>

          <div className="mobile-player-seekbar">
            <div className="mobile-time-container dsp-flx pr-2">
              <p className="fnt-poppins fnt-r-14 fnt-dark-a m-0">{convertSeconds(Math.floor(seekBar))}</p>
            </div>
            <div className="ml-1 flx-1">
              <Slider
                displayName="Audio"
                min={0}
                max={Math.ceil(Number(length))}
                value={seekBar}
                step={1}
                onChange={(value) => {
                  dispatch({
                    type: TYPE_SET_SEEKBAR_POS_FOR_PLAYER,
                    payload: { position: value },
                  });
                }}
                onAfterChange={() => {
                  dispatch({ type: TYPE_LOCK_SEEKBAR_MUTEX });
                }}
                trackStyle={{ backgroundColor: '#FC650A' }}
                railStyle={{ backgroundColor: '#EDEDED' }}
                handleStyle={{
                  backgroundColor: '#FC650A',
                  borderColor: '#FC650A',
                }}
              />
            </div>
            <div className="mobile-time-container dsp-flx pl-2">
              <p className="fnt-poppins fnt-r-14 fnt-dark-a m-0">{convertSeconds(Math.ceil(Number(length)))}</p>
            </div>
          </div>
        </>
      )}
      <div
        className="mini-player-close"
        onClick={() => {
          props.handleCloseAudioPlayer();
          setClosePlayer(true);
        }}
      ></div>
    </div>
  );
};

export default MobilePlayer;
