import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import Logger from 'redux-logger';
import rootReducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middleWare = applyMiddleware(thunk);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['currentQuiz'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(rootReducer, middleWare)

export default () => {
  let store = createStore(persistedReducer, composeWithDevTools(middleWare));
  let persistor = persistStore(store);

  return { store, persistor };
};
