import React, { createContext, useReducer, useEffect } from 'react';
import { isNil } from 'lodash';
import GlobalAudioPlayer from '../components/audioPlayer/globalWrapper';

const LOCAL_STORAGE_KEY = 'ccc';

export const TYPE_LOG_IN = 'TYPE_LOG_IN';
export const TYPE_LOG_OUT = 'TYPE_LOG_OUT';
export const TYPE_SET_PLAYLIST = 'TYPE_SET_PLAYLIST';
export const TYPE_SET_TRACK = 'TYPE_SET_TRACK';
export const TYPE_TOGGLE_PLAY_PAUSE = 'TYPE_TOGGLE_PLAY_PAUSE';
export const TYPE_SET_PLAYER_PLAY = 'TYPE_SET_PLAYER_PLAY';
export const TYPE_SET_PLAYER_PAUSE = 'TYPE_SET_PLAYER_PAUSE';
export const TYPE_SET_VOLUME = 'TYPE_SET_VOLUME';
export const TYPE_SET_MUTE = 'TYPE_SET_MUTE';
export const TYPE_SET_UNMUTE = 'TYPE_SET_UNMUTE';
export const TYPE_TOGGLE_MINI_PLAYER = 'TYPE_TOGGLE_MINI_PLAYER';
export const TYPE_LOCK_INTERRUPT_MINI_PLAYER = 'TYPE_LOCK_INTERRUPT_MINI_PLAYER';
export const TYPE_UNLOCK_INTERRUPT_MINI_PLAYER = 'TYPE_UNLOCK_INTERRUPT_MINI_PLAYER';
export const TYPE_HIDE_MINI_PLAYER = 'TYPE_HIDE_MINI_PLAYER';
export const TYPE_SET_SEEKBAR_POS = 'TYPE_SET_SEEKBAR_POS';
export const TYPE_SET_SEEKBAR_POS_FOR_PLAYER = 'TYPE_SET_SEEKBAR_POS_FOR_PLAYER';
export const TYPE_LOCK_SEEKBAR_MUTEX = 'TYPE_LOCK_SEEKBAR_MUTEX';

// Audio Play state CONSTs
export const STATE_PLAYING = 0;
export const STATE_PAUSED = 1;
export const STATE_BUFFERING = 2;
export const STATE_STOPPED = 3;

export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case TYPE_LOG_IN:
      return {
        ...state,
        auth: {
          ...state.auth,
          first_name: action.payload.user_nicename,
          last_name: '',
          username: action.payload.user_display_name,
          email: action.payload.user_email,
          token: action.payload.token,
          avatar: '',
          userId: action.payload.user_id,
          rcpUserId: action.payload.rcp_user_id,
          memberships: action.payload.memberships || [],
          payments: action.payload.payments || [],
        },
        loggedIn: true,
      };
    case TYPE_LOG_OUT:
      return {
        ...state,
        auth: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          token: '',
          avatar: '',
          userId: '',
          rcpUserId: '',
          memberships: [],
          payments: [],
        },
        loggedIn: false,
      };
    case TYPE_SET_PLAYLIST:
      return {
        ...state,
        audio: {
          ...state.audio,
          playlist: action.payload.playlist,
          currentTrackId: action.payload.track,
          currentTrackGroupId: action.payload.trackGroup,
          currentTrackIndex: action.payload.trackIndex,
          currentBookTitle: action.payload.bookTitle,
        },
      };
    case TYPE_SET_TRACK:
      return {
        ...state,
        audio: {
          ...state.audio,
          currentTrackId: action.payload.track,
          currentTrackIndex: action.payload.trackIndex,
        },
      };
    case TYPE_TOGGLE_PLAY_PAUSE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: action.payload,
        },
      };
    case TYPE_TOGGLE_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          show: action.payload,
        },
      };
    case TYPE_LOCK_INTERRUPT_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          interrupt: true,
        },
      };
    case TYPE_UNLOCK_INTERRUPT_MINI_PLAYER:
      return {
        ...state,
        miniPlayer: {
          ...state.miniPlayer,
          interrupt: false,
        },
      };
    case TYPE_SET_PLAYER_PLAY:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: true,
        },
      };
    case TYPE_SET_VOLUME:
      return {
        ...state,
        audio: {
          ...state.audio,
          volume: action.payload,
        },
      };
    case TYPE_SET_MUTE:
      return {
        ...state,
        audio: {
          ...state.audio,
          mute: true,
        },
      };
    case TYPE_SET_UNMUTE:
      return {
        ...state,
        audio: {
          ...state.audio,
          mute: false,
        },
      };
    case TYPE_SET_PLAYER_PAUSE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playState: false,
        },
      };
    case TYPE_SET_SEEKBAR_POS:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarPosition: action.payload,
        },
      };
    case TYPE_SET_SEEKBAR_POS_FOR_PLAYER:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarPosition: action.payload.position,
          seekBarMutex: true,
        },
      };
    case TYPE_LOCK_SEEKBAR_MUTEX:
      return {
        ...state,
        audio: {
          ...state.audio,
          seekBarMutex: false,
        },
      };
    default:
      return state;
  }
};

const GlobalContextProvider = ({ children }) => {
  const initState = {
    loggedIn: false,
    language: 'eng', // oneOf(['eng','esp'])
    langChoices: ['eng', 'esp'],
    flagImages: {
      eng: 'https://mk0childrensceno0ux5.kinstacdn.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png',
      esp: 'https://mk0childrensceno0ux5.kinstacdn.com/wp-content/plugins/sitepress-multilingual-cms/res/flags/es.png',
    },
    auth: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      token: '',
      avatar: '',
      userId: '',
      rcpUserId: '',
      memberships: [],
      payments: [],
    },
    audio: {
      playlist: [],
      currentTrackId: '',
      currentTrackGroupId: '',
      currentTrackIndex: 0,
      currentBookTitle: '',
      playState: STATE_STOPPED,
      seekBarPosition: 0,
      seekBarMutex: false,
      volume: 100,
      mute: false,
    },
    miniPlayer: {
      show: false,
      interrupt: false,
    },
  };

  const rehydrateContext = (init) => {
    if (typeof window !== `undefined`) {
      var state = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
      if (state) {
        state['miniPlayer'] = {
          show: 'false',
        };
        state['audio'] = {
          ...state.audio,
          playState: STATE_STOPPED,
        };
      }

      return state;
    }
    // if window is undefined (during the build or when a refresh occurs)
    else {
      return initState;
    }
  };
  const [state, dispatch] = useReducer(reducer, initState, rehydrateContext);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  }, [state]);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
        <GlobalAudioPlayer />
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

// const SSRProofLocalStorageProvider = ({ children }) => {
//     const [localStore, setLocalStore] = useState(null)
//     useEffect(() => {
//         if (!(typeof (window) === 'undefined')) {
//             setLocalStore(localStorage)
//         }
//     }, [])

//     return (
//         <GlobalContextProvider
//             localStoreObject={localStore}
//         >
//             {children}
//         </GlobalContextProvider>
//     )
// }

export default GlobalContextProvider;
