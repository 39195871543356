// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-quiz-index-js": () => import("./../../../src/components/quiz/index.js" /* webpackChunkName: "component---src-components-quiz-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-index-js": () => import("./../../../src/pages/books/index.js" /* webpackChunkName: "component---src-pages-books-index-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-iframegames-[slug]-js": () => import("./../../../src/pages/iframegames/[slug].js" /* webpackChunkName: "component---src-pages-iframegames-[slug]-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-verify-qr-js": () => import("./../../../src/pages/verify-qr.js" /* webpackChunkName: "component---src-pages-verify-qr-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-chapters-js": () => import("./../../../src/templates/chapters.js" /* webpackChunkName: "component---src-templates-chapters-js" */),
  "component---src-templates-edit-profile-js": () => import("./../../../src/templates/editProfile.js" /* webpackChunkName: "component---src-templates-edit-profile-js" */),
  "component---src-templates-free-book-js": () => import("./../../../src/templates/freeBook.js" /* webpackChunkName: "component---src-templates-free-book-js" */),
  "component---src-templates-games-js": () => import("./../../../src/templates/games.js" /* webpackChunkName: "component---src-templates-games-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-membership-js": () => import("./../../../src/templates/membership.js" /* webpackChunkName: "component---src-templates-membership-js" */),
  "component---src-templates-profile-js": () => import("./../../../src/templates/profile.js" /* webpackChunkName: "component---src-templates-profile-js" */)
}

