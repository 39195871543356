import React from 'react';
import { MdPlayCircleOutline, MdPlayCircleFilled } from 'react-icons/md';

const PlaylistRow = ({ title, playing, time, trackIndex, onSelect }) => {
  return (
    <div className="playlist-row-container" onClick={onSelect} onKeyDown={() => {}}>
      <div className="playlist-left-container">
        <div>
          {playing ? (
            <MdPlayCircleFilled color="#FC650A" size={24} />
          ) : (
            <MdPlayCircleOutline color="#EDEDED" size={24} />
          )}
        </div>
        <div className="ml-3 playlist-text">
          <p
            className={`fnt-poppins fnt-14 m-0 ${playing ? 'playlist-text-active' : 'playlist-text-inactive'}`}
          >{`${title}`}</p>
        </div>
      </div>
      <div>
        <p className={`mb-0 ${playing ? 'playlist-text-active' : 'playlist-text-inactive'}`}>{`${
          playing ? `-${time}` : time
        }`}</p>
      </div>
    </div>
  );
};
export default PlaylistRow;
