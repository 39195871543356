import React, { useEffect, useState } from 'react';
import { isNil, isEqual } from 'lodash';
import {
  TYPE_TOGGLE_MINI_PLAYER,
  TYPE_SET_PLAYER_PLAY,
  TYPE_SET_SEEKBAR_POS,
  TYPE_TOGGLE_PLAY_PAUSE,
  STATE_BUFFERING,
  STATE_PLAYING,
  TYPE_SET_PLAYER_PAUSE,
  STATE_PAUSED,
} from '../../store/reducer';
import MiniPlayer from './miniPlayer';
import { useDispatch, useSelector } from 'react-redux';
import MobilePlayer from './mobilePlayer';

var mainAudioObject = null;

const AudioComponent = () => {
  const {
    playState,
    playlist,
    currentBookTitle,
    currentTrackIndex,
    seekBarPosition,
    seekBarMutex,
    volume,
    mute,
    currentBook,
    currentTrackId,
  } = useSelector((state) => state.audio);
  const mobileShow = useSelector((state) => state.mobilePlayer.show);
  const mobileInterrupt = useSelector((state) => state.mobilePlayer.interrupt);
  const { audio } = useSelector((state) => state);
  const { show, interrupt } = useSelector((state) => state.miniPlayer);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();

  const handleInitializeAudioState = (url = '') => {
    if (!isNil(mainAudioObject)) {
      mainAudioObject.pause();
      mainAudioObject.remove();
      mainAudioObject = null;
    }
    mainAudioObject = new Audio(url);
    mainAudioObject.addEventListener('timeupdate', () => {
      dispatch({
        type: TYPE_SET_SEEKBAR_POS,
        payload: mainAudioObject.currentTime,
      });
    });
    mainAudioObject.addEventListener('loadstart', () => {
      dispatch({ type: TYPE_TOGGLE_PLAY_PAUSE, payload: STATE_BUFFERING });
    });
    mainAudioObject.addEventListener('loadeddata', () => {
      mainAudioObject.currentTime = seekBarPosition || 0;
      dispatch({
        type: TYPE_TOGGLE_PLAY_PAUSE,
        payload: count === 0 ? STATE_PAUSED : STATE_PLAYING,
      });
      dispatch({ type: TYPE_TOGGLE_MINI_PLAYER, payload: true });
    });
  };

  const handleToggleAudioPlayState = (state) => {
    if (isEqual(state, STATE_PLAYING)) {
      try {
        mainAudioObject.play();
      } catch (error) {}
    }
    if (isEqual(state, STATE_PAUSED)) {
      mainAudioObject.pause();
    }
  };

  const handleCloseAudioPlayer = () => {
    dispatch({ type: TYPE_TOGGLE_MINI_PLAYER, payload: false });
    dispatch({
      type: TYPE_TOGGLE_PLAY_PAUSE,
      payload: STATE_PAUSED,
    });
  };

  const ensureCurrentTrackPlaying = () => {
    dispatch({ type: TYPE_SET_PLAYER_PLAY });
  };

  useEffect(() => {
    if (!isNil(mainAudioObject)) {
      handleToggleAudioPlayState(playState);
    }
  }, [playState, mainAudioObject]);

  useEffect(() => {
    if (currentTrackIndex > -1) {
      const audioUrl = playlist[currentTrackIndex]?.audio;
      handleInitializeAudioState(audioUrl);
      ensureCurrentTrackPlaying();
    }
  }, [currentTrackIndex, currentTrackId, playlist[currentTrackIndex]?.audio]);

  useEffect(() => {
    if (mute) {
      if (!isNil(mainAudioObject)) mainAudioObject.volume = 0;
    } else {
      if (!isNil(mainAudioObject)) mainAudioObject.volume = volume / 100;
    }
  }, [volume, mute, mainAudioObject]);

  useEffect(() => {
    if (!seekBarMutex && !isNil(mainAudioObject)) {
      mainAudioObject.currentTime = seekBarPosition || 0;
    }
  }, [seekBarMutex, mainAudioObject]);

  useEffect(() => {
    setCount(1);
  }, []);

  const currentTrack = audio.playlist[audio.currentTrackIndex];

  return (
    <div>
      <div className="hide-mobile">
        <MiniPlayer
          show={show & !interrupt}
          audio={audio}
          bookTitle={currentBookTitle}
          handleCloseAudioPlayer={handleCloseAudioPlayer}
        />
      </div>
      <div className="hide-desktop">
        {mobileShow && !mobileInterrupt ? (
          <MobilePlayer
            cover={currentTrack?.cover || ''}
            playState={audio.playState}
            length={currentTrack?.length || 100}
            seekBar={audio.seekBarPosition || 0}
            subTitle={currentBookTitle}
            title={currentTrack?.title || ''}
            chapterIndex={currentTrack.chapter}
            book={currentBook}
            handleCloseAudioPlayer={handleCloseAudioPlayer}
          />
        ) : null}
      </div>
    </div>
  );
};
export default AudioComponent;
